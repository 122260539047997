import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Text from "./../Text";
import { Spacing } from "./../Layout";
import FollowListingButton from "./../../containers/CompanyFollowButton";
import CompanyNotificationsButton from "./../CompanyNotificationsButton";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";
import CompanyLogo from "./CompanyLogo";
import CompanyPageLink from "../CompanyPageLink";

const Company = styled.div`
  display: flex;
  & > :first-child {
  flex-basis: 72px;
  min-width:72px;
  }
  img, picture { margin-bottom: 0.5rem }
`;

const HeaderName = ({
  company,
  listing,
  whiteText,
  onLinkClick,
  showFollows,
  tickerAs = 'h3',
  companyNameAs
}) => (
  <Company>
    <CompanyPageLink companyId={company.id} onClick={onLinkClick}>
      <CompanyLogo companyId={company.id} />
    </CompanyPageLink>
    <Spacing
      left={0.5}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <CompanyPageLink companyId={company.id} onClick={onLinkClick}>
        <Text white={whiteText} bolder nowrap size={1.2} as={tickerAs}>
          {listing.listingTicker}
        </Text>
      </CompanyPageLink>
      <CompanyPageLink companyId={company.id} onClick={onLinkClick}>
        <Text white={whiteText} size={0.78} as={companyNameAs}>
          {company.companyName}
        </Text>
      </CompanyPageLink>
      <div>
        {listing && <FollowListingButton listingId={listing.id} />}{" "}
      </div>
    </Spacing>
  </Company>
);

const HeaderLong = ({
  company,
  listing
}) => (
  <Company>
    <CompanyLogo companyId={company.id} />
    <Spacing horizontal={1}>
      <Text white bolder size={1.5} as="h1">
        {company.companyName} ({listing.listingTicker}) <Text white nowrap as="span" size={1.25}>Share Price & News</Text>
      </Text>
      <br />
      {listing && <FollowListingButton listingId={listing.id} />}{" "}
    </Spacing>
  </Company>
);

const HeaderUnlisted = ({
  company
}) => (
  <Company>
    <CompanyLogo companyId={company.id} />
    <Spacing horizontal={1}>
      <Text white bolder size={1.5} as="h1">
        {company.companyName} <Text white nowrap as="span" size={1.25}>Profile & News</Text>
      </Text>
      <br />
      {company && <CompanyNotificationsButton
                    companyId={company.id}
                  /> }
    </Spacing>
  </Company>
)

class HeaderNameContiner extends React.Component {
    static propTypes = {
    listingId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired
  };

  componentDidMount() {
    !this.props.listing && this.props.listingId && this.props.fetchListing(this.props.listingId);
    !this.props.company && this.props.fetchCompany(this.props.companyId);
  }

  render() {
    if(this.props.company){
      if(this.props.longHeader && this.props.listing){
        return <HeaderLong company={this.props.company} listing={this.props.listing} />
      }
      if(this.props.longHeader && this.props.company){
        return <HeaderUnlisted company={this.props.company} />
      }
      return (<HeaderName
                company={this.props.company}
                listing={this.props.listing}
                whiteText={this.props.whiteText}
                onLinkClick={this.props.onLinkClick}
                showFollows={this.props.showFollows}
                tickerAs={this.props.tickerAs}
                companyNameAs={this.props.companyNameAs} 
              />)
    }
    return null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    listing: state.advCompanies.listings[props.listingId],
    company: state.advCompanies.companies[props.companyId]
  };
};

const mapDispatchToProps = {
  fetchListing: listingId => Actions.advCompanies.listings.getById(listingId),
  fetchCompany: companyId => Actions.advCompanies.companies.getById(companyId)
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderNameContiner);
