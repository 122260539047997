import { createSelector } from "reselect";
import createCachedSelector from "re-reselect";

//-----------------------------------------------------
// users
//-----------------------------------------------------
export const selectAllUsers = (state) => state.users;
export const selectUserByLogin = createCachedSelector(
  selectAllUsers,
  (state, login) => login,
  (users, login) => Object.values(users).find((user) => user.login === login)
)((state, login) => login);

//----------------------------------------------
// watchlist
//----------------------------------------------
export const defaultCurrentWatchlistId = state => state.ui.watchlist.currentId;
export const selectIsWatchlistCollapsed = state => state.ui.watchlist.collapsed;
export const selectWatchlistSortField = state => state.ui.watchlist.sortField;
export const selectWatchlistSortOrder = state => state.ui.watchlist.sortOrder;
export const allWatchlists = state => state.watchlists2.lists;
export const allWatchlistsItems = state => state.watchlists2.items;

export const currentWatchlistId = createSelector(
  [defaultCurrentWatchlistId, allWatchlists],
  (currentId, watchlists) =>
    watchlists[currentId] ? currentId : Object.keys(watchlists)[0]
);

export const makeSelectIsListingWatched = listingId =>
  createSelector(
    allWatchlistsItems,
    items =>
      Object.values(items).find(
        watchlistItem => watchlistItem.listingId === listingId
      )
        ? true
        : false
  );

export const selectCurrentWatchedListingIds = createSelector(
  [currentWatchlistId, allWatchlistsItems],
  (watchlistId, watchlistItems) =>
    Object.values(watchlistItems)
      .filter(item => item.watchlistId === watchlistId)
      .map(item => item.listingId)
);

//----------------------------------------------
// //NewsMetaData
//----------------------------------------------
export const selectAllNewsMetaData = state => state.newsMetadata.byStoryId;
export const makeSelectNewsMetaDataByTags = tags =>
  createSelector(
    selectAllNewsMetaData,
    dataObjects =>
      Object.values(dataObjects).filter(metaData =>
        metaData.tags.some(r => tags.includes(r))
      )
  );
export const makeSelectNewsMetaDataByTimelineOwnerId = ownerId =>
  createSelector(
    selectAllNewsMetaData,
    dataObjects =>
      Object.values(dataObjects).filter(
        metaData => metaData.timelineOwnerId === ownerId
      )
  );

//chart data
export const makeSelectIndexCategoriesChartData = indexId =>
  createSelector(
    [selectReportIndexSectors, selectAllSectors],
    (report, sectors) => {
      if (report) {
        const chartData = report.categories
          .map((o, index) => {
            const sector = sectors ? sectors[o.sectorId] : null;
            return {
              label: sector
                ? sector.sectorDescription
                : sectors
                ? "Others"
                : "-",
              id: o.sectorId === "none" ? undefined : o.sectorId,
              value: o.count,
              hoverText: "listings"
            };
          })
          .sort((a, b) => b.value - a.value);
        return chartData;
      }

      return null;
    }
  );

// export const selectSectorsCatagisedCurrent = createSelector(
//   [selectSectorsCatagisedByIndexId, selectMarketIndexSectorId],
//   (report, sectorId) => report
// );

//exchanges
export const selectExchangeById = (state, exchangeId) =>
  state.advCompanies.exchanges[exchangeId];

//issues
export const allIssues = state => state.advCompanies.issues;
export const selectIssueById = (state, issueId) => allIssues(state)[issueId];
export const selectIssuesByCompanyId = createCachedSelector(
  [(state, companyId) => companyId, allIssues],
  (companyId, issues) => {
    const found = Object.values(issues).filter(
      issue => issue.issueCompanyId === companyId
    );
    return found.length > 0 ? found : null;
  }
)((state, companyId) => companyId);


//listings
export const allCompanies = state => state && state.advCompanies ? state.advCompanies.companies : {};
export const allListings = state => state.advCompanies.listings;
export const selectListingById = (state, listingId) =>
  allListings(state)[listingId];
export const selectListingByTicker = (state, shortTicker, exchangeCode) => {
  const listings = Object.values(allListings(state)).filter(
    listing => shortTicker === listing.listingShortTicker
  );
  if(listings.length === 1){
    return listings[0]
  }
  if(listings.length > 1){
    const current = listings.filter( listing => listing.listingEndDate === null );
    if( current.length === 1 ){
      return current[0];
    }
    if( exchangeCode && exchangeCode === "LON" ){
      const lseListing = listings.filter( listing => listing.listingExchangeId === "5993224227bb7e00119e87c0" );
      if( lseListing.length === 1 ){
        return lseListing[0];
      }
      if( lseListing.length > 1 ){
        return lseListing[ lseListing.length - 1 ];
      }
    }
    return listings[ listings.length - 1 ];
  }
  return null;
}
export const selectListingsByIssueId = createCachedSelector(
  [(state, issueId) => issueId, allListings],
  (issueId, allListings) => {
    return Object.values(allListings).filter(
      listing => listing.issueId === issueId
    );
  }
)((state, issueId) => issueId);

export const selectDefaultListing = (
  listings = [],
  exchangeId = "5993224227bb7e00119e87c0"
) => {
  if (!listings || listings.length == 0) {
    return null;
  } else if (listings.length == 1) {
    return listings[0];
  } else if (listings.length > 1) {
    let listing = listings.find(
      listing =>
        listing.listingExchangeId === exchangeId &&
               listing.listingEndDate === null
    );
    if (listing) {
      return listing;
    }
    else {
      listing = listings.find(
        listing => listing.listingEndDate === null
      );
    }
    if (listing) {
      return listing;
    }
    return listings[0];
  }
  return null;
};

export const selectListingsByCompanyId = createCachedSelector(
  [
    state => state,
    (state, companyId) => selectIssuesByCompanyId(state, companyId)
  ],
  (state, issues) => {
    if (issues) {
      return issues.reduce(
        (listings, issue) => [
          ...listings,
          ...selectListingsByIssueId(state, issue.id)
        ],
        []
      );
    }
    return null;
  }
)((state, companyId) => companyId);

export const selectCompanyByListingId = createCachedSelector(
  [
    (state, listingId) => selectListingById(state, listingId),
    allIssues,
    allCompanies
  ],
  (listing, issues, companies) => {
    if (listing) {
      const issue = issues[listing.issueId];
      if (issue) {
        return companies[issue.issueCompanyId];
      }
    }
    return null;
  }
)((state, listingId) => listingId);

export const makeSelectorListingsByIssueId = issueId =>
  createSelector(
    allListings,
    listings =>
      Object.values(listings).filter(listing => listing.issueId === issueId)
  );

// company page url
export const selectCompanyPageURL = (state, companyId) => {
  const company = state.advCompanies.companies[companyId];
  if(!company){
    return null;
  }
  if(company){
    const unlisted = company.hasOwnProperty('companySlug') && company.companySlug != '';
    if(unlisted){
      return `/company/${company.companySlug}/`;
    }
  }
  const listing = selectDefaultListing(
    selectListingsByCompanyId(state, companyId)
  );
  const useListingPageURLs = selectListingPageLinks(state);
  if (useListingPageURLs) {
    const exchange = listing
                   ? selectExchangeById(state, listing.listingExchangeId)
                   : null;
    return listing && exchange
         ? `/listings/${exchange.exchangeFactSetCode}/${
          listing.listingShortTicker
        }/`
         : "";
  } else {
    return listing ? `/company/${listing.listingShortTicker}` : "";
  }
};

export const selectListingPageURL = (state, listingId) => {
  const listing = selectListingById(state, listingId);
  const useListingPageURLs = true;
  if (useListingPageURLs) {
    const exchange = listing
                   ? selectExchangeById(state, listing.listingExchangeId)
                   : null;
    return listing && exchange
         ? `/listings/${exchange.exchangeFactSetCode}/${
          listing.listingShortTicker
        }/`
         : "";
  } else {
    return listing ? `/company/${listing.listingShortTicker}` : "";
  }
};

export const selectEndedWatchedListings = createSelector(
  [allWatchlistsItems, allListings],
  (items, listings) => {
    return Object.values(items)
                 .filter(
                   item =>
                     listings.hasOwnProperty(item.listingId) &&
                         listings[item.listingId].listingEndDate
                 )
                 .map(item => ({ ...item, listing: listings[item.listingId] }));
  }
);

//----------------------------------------------
// //companies
//----------------------------------------------
export const selectCompanyById = (state, companyId) =>
  allCompanies(state)[companyId];

export const selectCompanyBySlug = (state, slug) =>
  state.advCompanies.companies ? Object.values(state.advCompanies.companies).find( company => company.companySlug === slug ) : null;

export const selectCurrentWatchedCompanies = createSelector(
  [allCompanies, allIssues, selectCurrentWatchedListingIds, allListings],
  (comapnies, issues, listingIds, allListings) => {
    const newCompanies = {};
    listingIds.forEach(listingId => {
      if (allListings[listingId]) {
        const listing = allListings[listingId];
        const issue = issues[listing.issueId];
        if (issue) newCompanies[listingId] = comapnies[issue.issueCompanyId];
      }
    });

    return newCompanies;
  }
);

//search
export const searchQuery = state => state.ui.search.query;
export const searchQueryCompanies = state =>
  state.ui.search.results.companies.query;

export const searchCompanies = createSelector(
  [allCompanies, allIssues, allListings, searchQuery],
  (companies, issues, listings, query) => {
    const companiesA = Object.values(companies);
    const issuesA = Object.values(issues);
    const listingsA = Object.values(listings);
    const queryRegex = new RegExp(
      query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
      "i"
    );

    return query.length >= 3
         ? companiesA
           .map(company => {
             const issue = issuesA.find(
               issue => issue.issueCompanyId === company.id
             );
             const listing = issue
                           ? listingsA.find(listing => listing.issueId === issue.id)
                           : null;
             return {
               companyId: company.id,
               companyName: company.companyName,
               listingId: listing ? listing.id : "",
               shortTicker: listing ? listing.listingShortTicker : ""
             };
           })
           .filter(
             hit =>
                  (hit.companyName.match(queryRegex) ||
                   hit.shortTicker.match(queryRegex))
           )
         : [];
  }
);

//ListingExtra - listing with company data
export const selectAllListingsExtra = createSelector(
  [allCompanies, allIssues, allListings],
  (companies, issues, listings, query) => {
    const companies_ = Object.values(companies);
    const issues_ = Object.values(issues);
    const listings_ = Object.values(listings);

    return listings_.map(listing => {
      const issue = issues_.find(issue => issue.id === listing.issueId);
      const company = issue
                    ? companies_.find(company => company.id === issue.issueCompanyId)
                    : null;
      return {
        company,
        issue,
        listing
      };
    });
  }
);

export const searchListings = createSelector(
  [allListings, searchQuery],
  (listings, query) => {
    const listingsA = Object.values(listings);
    const queryRegex = new RegExp(
      query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
      "i"
    );

    return query.length >= 1
         ? listingsA
           .filter(listing => listing.listingShortTicker.match(queryRegex))
           .map(listing => {
             return {
               listingId: listing.id,
               shortTicker: listing.listingShortTicker || "",
               listingEndDate: new Date(listing.listingEndDate)
             };
           })
         : [];
  }
);

//userData
export const selectLogedUserId = state => state.userData.id;
export const selectIsLivePrices = state => state.userData.livePricing === true;
export const selectListingPageLinks = state => true;

//auth
export const loggedIn = state => state.authentication.loggedIn;
export const loggingIn = state => state.authentication.loggingIn;
export const selectIsAuthenticationCompleted = state =>
  state.ui.authentication.completed;

//ui.login
export const loginOverlay = state => state.ui.loginOverlay;

//----------------------------------------------
// ui
//----------------------------------------------
export const selectWindowWidth = state => state.ui.client.windowWidth;

//----------------------------------------------
// reports
//----------------------------------------------
export const selectAllReports = state => state.reports;
export const selectReportByName = (state, reportName) =>
  state.reports[reportName];
export const selectReportIndexSectors = (state, indexId) =>
  selectAllReports(state)[`categorisedBySector-${indexId}`];

// export const selectSectorsCatagisedCurrent = createSelector(
//   [selectSectorsCatagisedByIndexId, selectMarketIndexSectorId],
//   (report, sectorId) => report
// );

//----------------------------------------------
// shortPositions
//----------------------------------------------
export const selectAllShortPositions = state => state.shortPositions;
export const selectShortPositionsByIssueId = createCachedSelector(
  [selectAllShortPositions, (state, issueId) => issueId],
  (shortPositions, issueId) =>
    Object.values(shortPositions).filter(
      shortPosition => shortPosition.issueId === issueId
    )
)((state, issueId) => issueId);
export const selectShortPositionsByIssueIdReport = createCachedSelector(
  [
    (state, reportName, issueId) => issueId,
    selectAllShortPositions,
    (state, reportName, issueId) => selectReportByName(state, reportName)
  ],
  (issueId, shortPositions, report) => {
    const recordDate = report ? report.recordDate : null;
    return !recordDate
         ? []
         : Object.values(shortPositions).filter(
           shortPosition =>
             shortPosition.recordDate === recordDate &&
                          (issueId === undefined || issueId === shortPosition.issueId)
         );
  }
)((state, reportName, issueId) => `${reportName}:${issueId}`);

//------------------------------------------------
//sector
//------------------------------------------------
export const selectMarketIndexSectorIds = state =>
  state.ui.marketIndex.sectorIds;
export const selectAllSectors = state => state.staticData.sectors;
export const selectSectorById = (state, sectorId) =>
  selectAllSectors(state)[sectorId];
export const selectSectorsSelected = createSelector(
  [selectMarketIndexSectorIds, selectAllSectors],
  (selectedIndexIds, sectors) =>
    Object.values(sectors).filter(sector =>
      selectedIndexIds.includes(sector.id)
    )
);
export const selectSectorsSelectedFilters = createSelector(
  [selectMarketIndexSectorIds, selectAllSectors],
  (selectedIndexIds, sectors) =>
    selectedIndexIds.map(sectorId => {
      const sector = Object.values(sectors).find(
        sector => sector.id === sectorId
      );
      return {
        id: sectorId,
        name: sector ? sector.sectorDescription : "Others"
      };
    })
);

// export const selectIndexSectors = createCachedSelector(
//   [selectAllSectors, selectReportIndexSectors],
//   (sectors, report) => {
//     console.log("ss5", sectors, report);
//     const x = Object.values(sectors).filter(sector =>
//       Object.values(report.categories).includes(sector.id)
//     );

//     console.log("x", x);

//     return x;
//   }
// )((state, indexId) => indexId);
//--------------------------------


//----------------------------------------------
// prices
//----------------------------------------------
export const selectAllQuotes = state => state.quotes;
export const selectQuoteByListingId = (state, listingId) =>
selectAllQuotes(state)[listingId];
export const selectCurrentWatchedPrices = createSelector(
  [selectCurrentWatchedListingIds, selectAllQuotes],
  (listingIds, prices) => {
    const newPrices = {};
    listingIds.forEach(listingId => {
      if (prices[listingId]) {
        newPrices[listingId] = prices[listingId];
      }
    });

    return newPrices;
  }
);

//indexes
export const selectAllIndexes = state => state.indexes.indexesById;
export const selectIndexByLeafname = (state, leafname) =>
  Object.values(selectAllIndexes(state)).find(
    index => leafname === index.indexLeafname
  );
export const selectIndexById = (state, id) => selectAllIndexes(state)[id]
export const selectAllIndexListingsIds = state =>
  state.indexes.listingsByIndexId;

export const selectListingsOnIndex = (state, indexId) => {
  const found =  state.indexes.listingsByIndexId[indexId] ? state.indexes.listingsByIndexId[indexId].map( id => selectListingById(state, id)) : null;
  if( found && found.length > 0 ){
    return found;
  }
  return null
}

export const selectIssuesOnIndex = createCachedSelector(
  [allIssues, selectListingsOnIndex],
  (issues, listings) => {
    if( listings ){
      const found = listings.map( listing => issues[ listing.issueId ] ).filter( issue => !!issue);
      if(found.length>0){
        return found;
      }
    }
    return null;
  }
)((state, indexId) => indexId);


export const makeSelectAllIndexListings = (state, indexId) => createSelector(
  [() => allListings, selectAllIndexListingsIds],
  (listings, indexes) => {
    return indexes.hasOwnProperty(indexId) ? indexes[indexId] : []
})


export const makeSelectSortedIndexListingsIds = indexId =>
  createSelector(
    [
      selectAllIndexListingsIds,
      selectAllListingsExtra,
      selectAllQuotes,
      selectMarketIndexSectorIds
    ],
    (marketListings, listingsExtra, quotes, selectedSectorIds) => {
      const listingsIds = marketListings[indexId];

      if (listingsIds) {
        let sectorListingsIds = null;
        //-----------
        if (selectedSectorIds.length > 0) {
          sectorListingsIds = listingsExtra
            .filter(
              entry =>
                entry.listing &&
                entry.company &&
                listingsIds.includes(entry.listing.id) &&
                selectedSectorIds
                  .map(sectorId => (sectorId === "none" ? undefined : sectorId))
                  .includes(entry.company.companySectorId)
            )
            .map(entry => entry.listing.id);
        }
        //-------
        const sorted = (sectorListingsIds || listingsIds).sort((a, b) => {
          const changeA = quotes.hasOwnProperty(a) ? quotes[a].pctChange : 0;
          const changeB = quotes.hasOwnProperty(b) ? quotes[b].pctChange : 0;
          if(isNaN(changeA) && isNaN(changeB)){
            return 0;
          }
          if(isNaN(changeA)){
            return changeB - 0;
          }
          if(isNaN(changeB)){
            return 0 - changeA;
          }
          
          return changeB - changeA;
        });
        return [...sorted];
      }

      return null;
    }
  );

//follows
export const selectAllFollows = state => state.follows.list;
export const selectMineFollows = state =>
  createSelector(
    [selectAllFollows, selectLogedUserId],
    (follows, userId) =>
      Object.values(follows).filter(follow => follow.owner === `${userId}`)
  );

export const selectMineFollowByCompanyId = (state, props) =>
  Object.values(selectAllFollows(state)).find(
    follow =>
      follow.followedId === props.companyId &&
      follow.followedType === "company" &&
      follow.owner === `${selectLogedUserId(state)}`
  );
export const selectMineFollowByFollowedId = (state, props) => {
  const owner = selectLogedUserId(state);
  return Object.values(selectAllFollows(state)).find(
    follow =>
      follow.followedId === `${props.followedId}` &&
      follow.followedType === props.followedType &&
      follow.owner === `${owner}`
  );
};

export const makeSelectFollowsByOwner = (owner, followedType, limit, skip) =>
  createSelector(
    selectAllFollows,
    followsObjects =>
      Object.values(followsObjects)
        .filter(
          follow =>
            follow.followedType === followedType && follow.owner === `${owner}`
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(skip, skip + limit)
  );

export const makeSelectFollowsByFollowedId = (
  followedId,
  followedType,
  limit,
  skip
) =>
  createSelector(
    selectAllFollows,
    followsObjects =>
      Object.values(followsObjects)
        .filter(
          follow =>
            follow.followedType === followedType &&
            follow.followedId === `${followedId}`
        )
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(skip, skip + limit)
  );

//----------------------------
//--- cached selectors
//----------------------------
//----example
//--- https://github.com/toomuchdesign/re-reselect/blob/master/examples/1-join-selectors.md
//-------------------------------
export const selectIsWatchedByCompannyId = createCachedSelector(
  [
    (state, companyId) => companyId,
    allIssues, // = (state, companyId) => allIssues(state, companyId)
    allListings,
    allWatchlistsItems
  ],
  (companyId, issues, listings, watchlistItems) => {
    const issuesIds = Object.values(issues)
      .filter(issue => issue.issueCompanyId === companyId)
      .map(issue => issue.id);

    const listingIds = Object.values(listings)
      .filter(listing => issuesIds.includes(listing.issueId))
      .map(listing => listing.id);

    return Object.values(watchlistItems).find(watchlistItem =>
      listingIds.includes(watchlistItem.listingId)
    )
      ? true
      : false;
  }
)(
  (state, companyId) => companyId // Cache selectors by state name
);

export const selectCompaniesByListingId = createCachedSelector(
  [selectListingsOnIndex, allIssues, allCompanies],
  (listings, issues, companies) => {

    if(!listings) {
      return null
    }
  
    return Object.values(listings).map((listing) => {
      const issue = issues[listing.issueId];
      return {
        listingId: listing.id,
        company: companies[issue?.issueCompanyId]
      }
    })

  }
)((state, indexId) => indexId);
