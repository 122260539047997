import React, { useContext }  from "react";
import styled, { css } from "styled-components";
import { format, formatDistance } from "date-fns";
import { Link } from "react-router-dom";
import Text from "components/Text";
import Badge from "components/Badge";
import Author from "components/ArticleAuthor";

import { Spacing, Grid, Cell } from "components/Layout";
import Image from "components/Image";

import ThemeContext from "components/ThemeContext";

import { useCategories, useAuthors } from "features/articles/queries";

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: left;
  text-align: left;
  margin: 0;
  position: absolute;
  top:0;
  left:0;
  right:0;
  padding:8px 8px 3rem 8px;
  background: linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
  img {
  transform: none !important;
  }
`;

const Summary = styled(Text)`
  min-height: 100px;
  font-size: 0.85rem;
  margin-top:0.5rem;
`;

const Wrapper = styled.div`

`;

const Anchor = styled(({ cover, ...props }) => <Link {...props} />)`
  display: inline-block;
  text-decoration: none !important;
  position: relative;
  overflow: visible;
  ${(props) =>
    props.cover &&
`max-width:100%; overflow: hidden;`};
img {
transition:transform 500ms;
transform: scale(1);
}
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

`;

const StyledLink = styled(({ top, ...props }) => <Link {...props} />)`
  text-decoration: none;
  display: inline-block;
`;

const BigText = styled(Text)`
    ${ props => props.theme && props.themeContext && props.themeContext === 'GCI' && `color: ${props.theme.color.gci};` }
    text-align: left;
    margin-top:8px;
`;

const Stig = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  span {
  margin-top: 4px;
  }
`;

const CoverWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  > ${Image}{
  position: absolute;
  top:50%;
  left: 50%;
  width:100%;
  transform: translate(-50%, -50%);
  }
`;


const getPermalink = ({ articleLeafname, categorySlug, articleContext, collectionIDs = [] }) => {
  if(articleLeafname){
    if(articleContext && articleContext === "GCI" || collectionIDs.includes('663b34c4c3a6e4e9682027c8') ){
      return `/gci/${encodeURIComponent(categorySlug)}/${articleLeafname}/`;
    }
    else {
      return `/articles/${articleLeafname}/`;
    }
  }
  else {
    if(articleContext && articleContext === "GCI"){
      return `/gci/${encodeURIComponent(categorySlug)}/`;
    }
    else {
      return `/all-articles/${encodeURIComponent(categorySlug)}/`;
    }
  }
}


const ArticleRow = ({ articleLeafname, articleFeaturedImageUrl, articleTitle, titleAs, articleSummary, isPublished, publishedAt, distance, articleCategory, categorySlug, articleAuthorId, author, articleContext, noLead, collectionIDs, ...props }) => {
  return <Grid>
    <Cell col={4}>
      <Link to={getPermalink({articleLeafname, categorySlug, articleContext, collectionIDs })} cover title={articleTitle} style={{ width: '100%' }}>
        <CoverWrapper>
          <Image
            src={articleFeaturedImageUrl}
            size={"PREVIEW"}
            alt={articleTitle}
          />
        </CoverWrapper>
      </Link>
      { /* articleAuthorId && (<Author
           cmsAuthor={author}
           userId={articleAuthorId}
           hideAvatar={false}
           dark={false}
           />
           ) */}
  </Cell>
  <Cell col={8}>
    <StyledLink to={getPermalink({articleLeafname, categorySlug, articleContext, collectionIDs })} cover title={articleTitle}>
      <Text as={titleAs} size={0.9} bold>
        {!isPublished && "[DRAFT] "}{articleTitle}
      </Text>
      {noLead === false && (
        <Text size={0.8} as="p" dark>
          {articleSummary}
        </Text>
      )}
    </StyledLink>
    <Spacing top={0.25}>
      <Text size={0.8} nowrap grey title={format(new Date(publishedAt), "HH:MM, d/MM/yyyy")}>
        { categorySlug ? (
            <StyledLink to={getPermalink({categorySlug, articleContext})} top>
              <Text size={0.8} >{articleCategory}</Text>
            </StyledLink>
        ) : <Text size={0.8}>{articleCategory}</Text>}
        {" "}{distance}
      </Text>
    </Spacing>
  </Cell>
  </Grid>
}

export default function Article({ article, row, noLead = false, titleAs = "h3", timeline }) {

  const { data: categories } = useCategories();
  const { data: authors } = useAuthors();
  const slugMap = categories ? categories.reduce( (acc, cur) => {
    if(cur.hasOwnProperty("slug")){
      return { [cur.value] : cur.slug, ...acc }
    }
    return acc;
  }, {}) : {}

  const articleContext = useContext(ThemeContext);

  if (!article) {
    return <Wrapper>
      <CoverWrapper>
      </CoverWrapper>
      <Spacing horizontal={timeline?1:0}>
        <BigText as={titleAs} size={1} bold>
          {" "}
        </BigText>
        <Text size={0.84} nowrap grey>
          {" "}
        </Text>
        <Summary as="p" dark lines={6}>
          {" "}
        </Summary>
      </Spacing>
    </Wrapper>
  }

  const {
    id,
    articleTitle,
    articleSummary,
    publishedAt,
    articleAuthorId,
    articleLeafname,
    articleCategory,
    articleFeaturedImageUrl,
    isMembersOnly,
    isPublished,
    collectionIDs,
    mentions
  } = article;

  const categorySlug = slugMap.hasOwnProperty(articleCategory) ? slugMap[articleCategory] : null;
  const author = authors ? authors.find( item => item.legacyUserId === articleAuthorId ) : null;

  const distance = formatDistance(new Date(publishedAt), new Date(), { addSuffix: true }).replace('about ','');

  if(row){
    return <ArticleRow {... { articleLeafname, articleFeaturedImageUrl, articleTitle, titleAs, articleSummary, isPublished, publishedAt, distance, articleCategory, categorySlug, articleAuthorId, author, articleContext, noLead, collectionIDs }} />
  }

  return (
    <Wrapper>
      <Anchor to={getPermalink({articleLeafname, categorySlug, articleContext, collectionIDs })} cover title={articleTitle}>
        {articleFeaturedImageUrl && (
          <CoverWrapper>
            <Image
              src={articleFeaturedImageUrl}
              size={"PREVIEW"}
              alt={articleTitle}
            />
            <Meta>
              {articleAuthorId && (
                <Author
                  cmsAuthor={author}
                  userId={articleAuthorId}
                  category={articleCategory}
                  categorySlug={categorySlug}
                  collectionIDs={collectionIDs}
                  mentions={mentions}
                  articleContext={articleContext}
                  hideAvatar={false}
                  dark={true}
                />
              )}
              <Stig>
                {isMembersOnly && (
                  <Spacing bottom={0.1}>
                    <Badge secondary>Members Only</Badge>
                  </Spacing>
                )}
                
              </Stig>
            </Meta>
          </CoverWrapper>
        )}
        <Spacing horizontal={timeline?1:0}>
          <BigText as={titleAs} size={1} bold themeContext={articleContext}>
            {!isPublished && "[DRAFT] "}{articleTitle}
          </BigText>
          <Text size={0.84} nowrap grey title={format(new Date(publishedAt), "HH:MM, d/MM/yyyy")}>
            {distance}
          </Text>
          {noLead === false && (
            <Summary as="p" dark lines={6}>
              {articleSummary}
            </Summary>
          )}
        </Spacing>
      </Anchor>
      
      
    </Wrapper>
  );
}
