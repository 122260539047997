import React from "react";
import HeaderPrice from "./HeaderPrice";
import HeaderName from "./HeaderName";
import { Grid, Cell } from "./../Layout";

const CompanyBadge = ({
  listingId,
  companyId,
  whiteText,
  onLinkClick,
  showFollows,
  tickerAs,
  companyNameAs,
  longHeader
}) => (
  <Grid noWrap>
    <Cell fill>
      <HeaderName
        listingId={listingId}
        companyId={companyId}
        whiteText={whiteText}
        onLinkClick={onLinkClick}
        showFollows={showFollows}
        tickerAs={tickerAs}
        companyNameAs={companyNameAs}
        longHeader={longHeader}
      />
    </Cell>
    { listingId && (
    <Cell fit>
      <HeaderPrice listingId={listingId} whiteText={whiteText} />
    </Cell> )}
  </Grid>
);

export default CompanyBadge;
