import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Img, Link } from "./Utils";
import Text from "./Text";
import { Grid, Cell, Spacing } from "./Layout";
import shortcode from "../utils/bbparse";
import PostAuthor from "../containers/PostHeader";
import Interactions from "../components/Interactions";
import AttachmentPreview from "../containers/Attachment";
import PostAttachmentPreview from "../containers/PostAttachments";
import { Comment } from "./CommentList";
import withListingIds from "./withListingIds";
import InViewport from "./InViewport";
import ListingBadgePopover from "./ListingBadgePopover";
import IndexLink from "./IndexLink";
import Mentions from "../containers/Mentions";

import MediaAsset from "features/media-assets/components/MediaAsset";
import Article from "features/articles/components/Article";
import { useArticleById } from "features/articles/queries";

const MarkTickers = ({ content = "" }) => {
  const tickers = content.match(/\s*#\S+\s*/g);
  if (tickers) {
    const trimmed = tickers.map(str => str.trim().replace("#", ""));
    return <Mentions tickers={trimmed} content={content} />;
  }
  return content;
};

const CompanyTarget = withListingIds(props => {
  return props.listing.listingShortTicker ? (
    <ListingBadgePopover atHover {...props}>
      <Text as="span" bold size={0.9} white={props.white}>
        {` ▸ `} #{props.listing.listingShortTicker}
      </Text>
    </ListingBadgePopover>
  ) : null;
});

const IndexTarget = ({ marketIndexId, ...props }) => {
  return (
    <Text as="span" bold size={0.9} white={props.white}>
      {` ▸ `} <IndexLink id={marketIndexId} />
    </Text>
  );
};

const getCommentPermalink = ({ metadata = {}, ...props }) => {
  const mapTypeToPath = {
    storyId: "rns/announcement",
    articleLeafName: "articles",
    article: "articles"
  };
  let { key, value } = Object.keys(metadata).reduce(
    (acc, cur) => {
      if (Object.keys(mapTypeToPath).includes(cur)) {
        return { key: mapTypeToPath[cur], value: metadata[cur] };
      }
      return acc;
    },
    { key: "post", value: props.id }
  );

  return `/${key}/${value}`;
};

const Cover = styled(({ className, ...props }) => (
  <div className={className}>
    <Img {...props} />
  </div>
))`
  ${Img} {
    max-width: 100%;
  }
`;

const BoxContent = styled.div`
  padding: 10px;
  & & {
    padding-left: 0;
    padding-right: 0;
  }
`;

const InteractionsFooter = styled.div`
  padding: 10px 10px 6px;
  margin-top: 10px;
  border-top: 1px solid #ddd;
  background: #f0f0f0;
  min-height: 50px;
`;

const Preview = props => {
  if (props.hasOwnProperty("file-attachment")) {
    return (
      <Cover
        src={`https://www.voxmarkets.co.uk/squawk_content/${
          props["file-attachment"].location
        }`}
      />
    );
  } else if (props.content) {
    return <AttachmentPreview content={props.content} />;
  }
  return null;
};

const AuthorHeader = ({ source, postType, ...props }) => {
  return postType &&
    postType.indexOf("user") === 0 ? (
    <PostAuthor
      userId={source}
      light={props.single}
      childern={props.children}
    />
  ) : (
    <PostAuthor companyId={source} light={props.single} />
  );
};

const HeaderWrap = styled(BoxContent)`
  ${Grid} {
    align-items: center;
  }
  & > ${Grid} {
    padding-bottom: 10px;
    margin-bottom: 10px;
    min-height: 60px;
    ${props => !props.single && `border-bottom: 1px solid #ddd;`};
  }
  & + ${BoxContent} {
    padding-top: 0;
  }
  ${props =>
    props.single
      ? `
    && {
    background: url("https://s3-eu-west-1.amazonaws.com/vox.store.images/user-cover/profile-bg-2.jpg") 50% 50% / cover;
    margin:-2.5rem -2rem 2rem;
        padding:0;
    }
    > ${Grid} {
      background: rgba(0, 0, 0, 0.5);
      padding:2.5rem 2rem;
        margin:0;
    }`
      : ""};
`;

const PostTarget = ({ targets, metadata, ...props }) => {
  if (targets && targets.length && props.postType === "companySquawk") {
    return <CompanyTarget companyId={targets[0]} white={props.single} />;
  } else if (metadata && metadata.hasOwnProperty("marketIndexId")) {
    return <IndexTarget marketIndexId={metadata.marketIndexId} />;
  }
  return null;
};

export const Header = ({ storyId, ...props }) => {
  let context = "";
  if (typeof window !== "undefined") {
    context =
      window.location.pathname.indexOf("/post") !== 0
        ? window.location.pathname
        : "";
  }
  const date = props.timestamp ? new Date(props.timestamp * 1000) : props.createdAt ? new Date(props.createdAt) : null;
  return (
    <HeaderWrap single={props.single}>
      <Grid>
        <Cell fill>
          <InViewport>
            <AuthorHeader {...props}>
              <PostTarget {...props} />
            </AuthorHeader>
          </InViewport>
        </Cell>
        <Cell fit>
          <Link
            href={
              storyId
                ? `/rns/announcement/${storyId}`
                : `/post/${props.id}/${context ? `?context=${context}` : ``}`
            }
            overlay
          >
            { date && (
            <Text size={0.75} light={!props.single} white={props.single} right>
              {format(date, "do MMMM yyyy")}
              <br />
              {format(date, "HH:mm")}
            </Text> )}
          </Link>
        </Cell>
      </Grid>
      {props.children}
    </HeaderWrap>
  );
};

export const Title = props => {
  if (props.children) {
    return (
      <Link href={props.href} overlay>
        <Text as="h3" bold size={1} {...props} />
      </Link>
    );
  }
  return null;
};

export const Content = ({ postContent, ...props }) => {
  const shortcodes = shortcode.parse(postContent);
  const text = shortcode.strip(postContent);
  const unlinked = text.replace(/https?[^\s]+/, "");
  const popovers = <InViewport><MarkTickers content={unlinked} Fallback={unlinked} /></InViewport>;
  return (
    <div>
      <Text dark size={0.9}>
        {popovers}
      </Text>
      <InViewport>
      <Preview {...shortcodes} content={text} />
      <PostAttachmentPreview postId={props.id} />
      </InViewport>
    </div>
  );
};

const GridWrapper = ({
  postContent,
  id,
  storyId,
  articleId,
  single,
  ...props
}) => {
  const content = (
    <Content postContent={postContent} id={id} />
  );
  const scope = storyId ? { storyId } : { postId: id };
  const Parent = () =>
    single ? (
      <Grid>
        <Cell mdCol={6} lgCol={8} smCol={12}>
          {content}
        </Cell>
        <Cell mdCol={6} lgCol={4} smCol={12}>
          <Interactions
            id={id}
            scope={scope}
            shareText={!storyId && postContent}
            showComments={single}
          />
        </Cell>
      </Grid>
    ) : (
      <>
        <BoxContent>{content}</BoxContent>
        <InteractionsFooter>
          <InViewport>
            <Interactions
              id={id}
              scope={scope}
              showComments={single}
              shareText={!storyId && postContent}
            />
          </InViewport>
        </InteractionsFooter>
      </>
    );

  return <Parent />;
};

export const Post = ({ title, postTitle, ...props }) => {
  let storyId = null;
  if (
    props.postType === "news" &&
    props.hasOwnProperty("metadata") &&
    props.metadata.storyId
  ) {
    storyId = props.metadata.storyId;
  }

  return (
    <>
      <Header {...props} storyId={storyId}>
        <Title href={storyId ? `/rns/announcement/${storyId}` : null}>
          {title || postTitle}
        </Title>
      </Header>
      <GridWrapper {...props} storyId={storyId} />
    </>
  );
};

export const CommentPost = props => {
  let permalink;
  if (props.targets && props.targets.length) {
    permalink = getCommentPermalink(props);
  }
  return (
    <>
      {permalink && (
        <AttachmentPreview
          content={`https://www.voxmarkets.co.uk${permalink}`}
        />
      )}

      <Comment {...props} permalink={permalink} />
    </>
  );
};

function ArticlePost({ articleId, ...props }) {
  const { data: article } = useArticleById(articleId);

  
  return (
    <>
      <InViewport>
        <Article article={article} timeline />
      </InViewport>
      <InteractionsFooter>
        <InViewport>
          <Interactions id={articleId} scope={{ articleId }} />
        </InViewport>
      </InteractionsFooter>
    </>
  );
};

const MediaPost = ({ mediaAssetId, ...props }) => {
  return (
    <>
      <Spacing horizontal={1} vertical={1}>
        <InViewport>
          <MediaAsset mediaAssetId={mediaAssetId} />
        </InViewport>
      </Spacing>
      <InteractionsFooter>
        <InViewport>
            <Interactions id={mediaAssetId} scope={{ mediaAssetId }} />
        </InViewport>
      </InteractionsFooter>
    </>
  );
};

const renderPostType = post => {
  switch (post.postType) {
    case "usercomment":
      return <CommentPost {...post} />;
    case "article":
      return <ArticlePost articleId={post.metadata.articleId} />;
    case "mediapost":
      return <MediaPost mediaAssetId={post.metadata.mediaAssetId} />;
    default:
      return <Post {...post} />;
  }
};

export default renderPostType;
