import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Actions } from "@voxmarkets/vox-reducers";

import {

  selectListingById,
  selectDefaultListing,
  selectListingsByCompanyId,
  selectCompanyPageURL,
  selectListingPageURL,
  selectIssuesByCompanyId
} from "./../redux/selectors";

import { Link } from "./Utils";

class CompanyPageLink extends PureComponent {
  componentDidMount() {
    if (!this.props.listing && this.props.companyId) {
      const issues = selectIssuesByCompanyId(this.props.companyId);
      if(!issues || issues.length === 0){
        this.props
            .getCompanyIssues(this.props.companyId)
            .then(resp => (resp && resp.length > 0 ? resp.result : null))
            .then(issues => {
              return issues
                   ? this.props.getIssueListings(issues.map(issue => issue.id))
                   : null;
            })
            .then(resp => (resp && resp.length > 0 ? resp.result : null))
            .then(listings => {
              return listings
                   ? this.props.getExchanges(listings.map(listing => listing.id))
                   : null;
            });
      }
      else {
        this.props.getIssueListings(issues.map(issue => issue.id))
            .then(listings => {
              return listings
                   ? this.props.getExchanges(listings.map(listing => listing.id))
                   : null;
            });
      }
    } else if (!this.props.listing && this.props.listingId) {
      this.props.getListing(this.props.listingId);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.listing && !this.props.url && this.props.listing.hasOwnProperty('listingExchangeId')) {
      this.props.getExchanges([this.props.listing.listingExchangeId]);
    }
    if (prevProps.loggedIn !== this.props.loggedIn) {
    }
  }
  
  render() {
    const {
      url,
      listing,
      listingId,
      companyId,
      loggedIn,
      getCompanyIssues,
      getIssueListings,
      getListing,
      getExchanges,
      children,
      ...rest
    } = this.props;
    return url ? <Link href={url} {...rest}>{children}</Link>: <>{children}</>;
  }
}

//-----------------------
//-----------------------
//-----------------------

const mapStateToProps = (state, { listing, listingId, companyId }) => {
  listing =
    listing ||
    (listingId
      ? selectListingById(state, listingId)
      : companyId
        ? selectDefaultListing(selectListingsByCompanyId(state, companyId))
        : null);
  listingId = listing ? listing.id : listingId;
  return {
    listing,
    url: companyId
      ? selectCompanyPageURL(state, companyId)
      : listingId
        ? selectListingPageURL(state, listingId)
        : null,
  };
};

const mapDispatchToProps = {
  getCompanyIssues: Actions.advCompanies.issues.getByCompanyId,
  getIssueListings: Actions.advCompanies.listings.getByIssueIds,
  getListing: Actions.advCompanies.listings.getById,
  getExchanges: Actions.advCompanies.exchanges.getByIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyPageLink);
