import { find, isEmpty, get } from "lodash/fp";
import { Actions, ActionTypes } from "@voxmarkets/vox-reducers";
import { v2API } from "@voxmarkets/api-sdk";
import { getMarketMovers } from "./api";
import { selectDefaultListing } from "./redux/selectors";
import { marketIndices } from "./constants";
import {getArticleQuery, getArticlesInfiniteQuery, getCategoriesQuery, getAuthorsQuery, getCategoryArticlesInfiniteQuery, getArticleBodyQuery, getUserArticlesQuery, getHomeArticlesQuery} from 'features/articles/queries'
import { getColectionQuery, getColectionArticlesQuery,  } from 'features/collections/queries'

import { recentMediaAssetsQuery,seriesMediaAssetsQuery, mediaAssetQuery } from "features/media-assets/queries"
import { seriesListQuery, selectArchived, selectByLeafname } from "features/series/queries"
import { reportQuery } from "features/reports/queries"
import getSrcset from "utils/getSrcset";

export const error404 = query => ({
  error: {
    status: 404,
    query
  }
});

const redirect = pathname => ({ 
  redirect: {
    pathname
  }
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async notFound(path) {
    return await error404(path.split("/").join(" "));
  },
  async company(store, params) {

    const slug = params.slug;
    const findBySlug = await store.dispatch(Actions.advCompanies.companies.getBySlug(slug))
                                 .then(res => res.result);

    if( findBySlug && findBySlug.length > 0 ){
      
      return { company: findBySlug[0] };
    }

    const ticker = params.slug.toUpperCase();
    
    const listings = await store.dispatch(Actions.advCompanies.listings.getByShortTicker(ticker))
                                .then(res => res.result);

    if (!listings || listings.length === 0) {
      return error404(params.ticker);
    }
    /* getByShortTicker returns a list using
         where { listingShortTicker: { like: shortTicker.toUpperCase() } }
         so we try to find a 100% match among the returned listings
         e.g. for ticker BOO, BOOT is the first match, BOO being farther down the list
      */
    const exactMatch = listings.filter(
      listing =>
        listing.listingShortTicker === ticker ||
        listing.listingTicker === ticker
    );

    let listing;
    if (exactMatch.length > 0) {
      listing = selectDefaultListing(exactMatch);
    } else {
      listing = selectDefaultListing(listings);
    }

    if (listing) {
      const exchange = await store
        .dispatch(
          Actions.advCompanies.exchanges.getByIds([listing.listingExchangeId])
        )
        .then(resp => {
          return resp && resp.result && resp.result.length === 1
            ? resp.result[0]
            : null;
        });
      return exchange
        ? redirect(
            `/listings/${exchange.exchangeFactSetCode}/${listing.listingShortTicker}/`
          )
        : error404(params.ticker);
    }

    return error404(params.ticker);
  },
  async listingPage(store, { exchangeCode, shortTicker }) {
    const exchange = await store
      .dispatch(
        Actions.advCompanies.exchanges.getExchangeByFactsetCode(exchangeCode)
      )
      .then(resp => {
        return resp && resp.result && resp.result.length === 1
          ? resp.result[0]
          : null;
      });

    if (!exchange || exchange.length === 0) {
      return error404(`${shortTicker} ${exchangeCode}`);
    }

    let listings = exchange.id
      ? await v2API.AdvCompanies.getListings({
          where: {
            and: [
              { listingExchangeId: exchange.id },
              { listingShortTicker: { like: shortTicker.toUpperCase() } }
            ]
          }
        }).then(listings => {
          store.dispatch({
            type: ActionTypes.ADV_CPNY_LNGS_GET_LIST_OK,
            result: listings
          });
          return listings;
        })
      : await store
          .dispatch(
            Actions.advCompanies.listings.getByShortTicker(
              shortTicker.toUpperCase()
            )
          )
          .then(resp => resp.result);
    if (!listings || listings.length === 0) {
      return error404(`${shortTicker} ${exchangeCode}`);
    }

    const exactMatch = listings.filter(
      listing =>
        listing.listingShortTicker === shortTicker ||
        listing.listingTicker === shortTicker
    );

    if (exactMatch.length > 0) {
      listings = exactMatch;
    }

    const issues = await store
      .dispatch(
        Actions.advCompanies.issues.getByIds(listings.map(item => item.issueId))
      )
      .then(resp => resp.result);

    const company =
      issues && issues.length > 0
        ? await store
            .dispatch(
              Actions.advCompanies.companies.getById(issues[0].issueCompanyId)
            )
            .then(resp =>
              resp.result && resp.result.length === 1 ? resp.result[0] : {}
            )
        : Promise.resolve({});

    const timeline = company.id
      ? await store.dispatch(
          Actions.chronology.fetchCompanyTimeline(company.id, {
            postType: "article,news,userpost,companySquawk,mediapost"
          })
        )
      : Promise.resolve({});
    const location = company.id
      ? await store
          .dispatch(Actions.advCompanies.locations.getByCompanyId(company.id))
          .then(resp => resp.result)
          .then(locations => {
            if (!locations || locations.length === 0) {
              return null;
            }
            if (locations.length === 1) {
              return locations[0];
            } else {
              let hq = locations.find(loc => loc.locationIsHQ);
              return hq ? hq : locations[0];
            }
          })
      : Promise.resolve(null);

    return {
      company: { ...company, ticker: listings[0].listingTicker, location }
    };
  },
  async article(store, params, queryClient) {
    const categories = await queryClient.fetchQuery(getCategoriesQuery())
    const recent = await queryClient.fetchInfiniteQuery(getArticlesInfiniteQuery())

    const article = await queryClient.fetchQuery(
      getArticleQuery(params.leafname, queryClient)
    );

    if(article) {
      queryClient.prefetchQuery(getArticleBodyQuery(article.id));
    }

    let author = {};
    if (!article) {
      let slug = params.leafname.split("-").join(" ");
      return error404(slug);
    }
    if (
      typeof article !== "undefined" &&
      article.hasOwnProperty("articleAuthorId")
    ) {
      author = await store.dispatch(
        Actions.users.fetchUserData(
          isNaN(article.articleAuthorId)
            ? parseInt(article.articleAuthorId)
            : article.articleAuthorId
        )
      );
    }
    if (Array.isArray(author)) {
      author = author.reduce((acc, cur) => {
        return cur.hasOwnProperty("id") ? cur : acc;
      }, {});
    }

    const images = getSrcset(article.articleFeaturedImageUrl, 'PREVIEW');
    
    return { author, article, recent, preload: [{ as:"image", href:images.src }] };
  },
  async post(store, params) {
    const post = await store
      .dispatch(Actions.chronology.fetchSingle(params.id))
      .then(res => res.result);
    if (post) {
      const author = await store
        .dispatch(
          Actions.users.fetchUserData(
            isNaN(post.source) ? parseInt(post.source) : post.source
          )
        )
        .then(res => res.find(item => item.hasOwnProperty("user_login")));

      let company = {};
      if (post.postType === "companySquawk" && post.targets.length === 1) {
        company = await store
          .dispatch(Actions.advCompanies.companies.getById(post.targets[0]))
          .then(res => res.result[0]);
      }

      const attachmentImage = await store
        .dispatch(Actions.chronology.fetchAttachments(params.id))
        .then(res =>
          res.result.timeline.find(att =>
            att.postContent.match(/\.(png|jpg|jpeg)$/i)
          )
        )
        .catch(e => {
          return {};
        });
      return {
        post: { ...post, authorName: author.name },
        author,
        attachmentImage,
        company
      };
    }
    return {};
  },
  async story(store, params) {
    const meta = await store
      .dispatch(Actions.newsMetadata.fetchForStories([params.storyId]))
      .then(stories => stories[params.storyId]);

    const announcement = await store.dispatch(Actions.rns.fetchContentByStoryIds([params.storyId]))

    let company = {};
      if (meta) {
      const { publishingCompanyId, publishingListingId } = meta;
      if( publishingCompanyId ){
        company = await store
          .dispatch(Actions.advCompanies.companies.getById(publishingCompanyId))
          .then(companies => companies.result[0]);
      }
      if( publishingListingId ){
        await store.dispatch(
          Actions.advCompanies.listings.getById(publishingListingId)
        );
        await store.dispatch(
          Actions.quotes.fetchQuotes([publishingListingId], false)
        );
      }
    }
    return {
      meta,
      company
    };
  },
  async allArticles(store, params, queryClient) {
    const categories = await queryClient.fetchQuery(getCategoriesQuery());
    const authors = await queryClient.fetchQuery(getAuthorsQuery());
    if(params && params.categorySlug){
      const articles = await queryClient.fetchInfiniteQuery(getCategoryArticlesInfiniteQuery(params.categorySlug, categories))
      return { articles, categories, authors }
    }
    const articles = await queryClient.fetchInfiniteQuery(getCategoryArticlesInfiniteQuery())
    return { articles, categories, authors };
  },
  //-----------------------
  async articlesCollection(store, params, queryClient) {    
    const collection = await queryClient.fetchQuery(getColectionQuery(params.leafname))
    
    if(collection) {
      const articles = await queryClient.fetchInfiniteQuery(getColectionArticlesQuery(collection.id))
      return { articles };
    }

    return {};
  },
  // -----------
  async authorArticles(store, params, queryClient, filters) {
    const categories = await queryClient.fetchQuery(getCategoriesQuery());
    const authors = await queryClient.fetchQuery(getAuthorsQuery());

    let user;
    
    const author = authors.find( a => a.slug === params.login );
    if(author){
      user = { ID: author.legacyUserId, name: author.name }
    }
    else {
      const authorUser = await store.dispatch(
        Actions.users.fetchUserByLogin(params.login)
      );
      const isAuthor = authors.find( a => a.legacyUserId === authorUser.ID.toString() );
      if( isAuthor ){
        return redirect(`/authors/${isAuthor.slug}/`);
      }
      else {
        return redirect(`/all-articles/`);
      }
    }
    
    let userData = null;
    let articles = null;

    if (user) {
      userData = await store.dispatch(Actions.users.fetchUserData(parseInt(user.ID)));
      articles = await queryClient.fetchInfiniteQuery(getUserArticlesQuery(user.ID, filters));    
    }
    return { articles, categories, author: userData };
  },
  async series(store, params, queryClient) {
    const allSeries = await queryClient.fetchQuery(seriesListQuery());
    const series = selectByLeafname(params.leafname)(allSeries)
    await queryClient.prefetchInfiniteQuery(seriesMediaAssetsQuery(series.id))
    
  },
  async media(params, queryClient) {    
    await queryClient.prefetchQuery(mediaAssetQuery(params.mediaId))
    await queryClient.prefetchQuery(seriesListQuery())
  },

  async videoArchives(queryClient) {
    const allSeries = await queryClient.fetchQuery(seriesListQuery());
    const archived = selectArchived(allSeries)
    const seriesMedia = archived.map((series) =>
      queryClient.prefetchInfiniteQuery(seriesMediaAssetsQuery(series.id))
    );
    await Promise.all(seriesMedia);
  },

  async marketIndex(store, params) {
    let indices = await store.dispatch(
      Actions.indexes.fetchIndexesByLeafname(params.leafname)
    );
    if (
      indices &&
      indices.hasOwnProperty("result") &&
      indices.result.length > 0
    ) {
      let index = indices.result[0];
      if (index) {
        let listings = await store
          .dispatch(Actions.indexes.fetchListingsByIndexId(index.id))
          .then(resp => resp.result);
        let issues = await store
          .dispatch(
            Actions.advCompanies.issues.getByIds(
              listings.map(listing => listing.issueId)
            )
          )
          .then(resp => resp.result);
        let companies = await store
          .dispatch(
            Actions.advCompanies.companies.getByIds(
              issues.map(issue => issue.issueCompanyId)
            )
          )
          .then(resp => resp.result);

        const prices = await Actions.quotes.fetchQuotes(
          listings.map(listing => listing.id),
          false
        );

        const daysBack =
          index.id === "5e7b76db1c9d440000482fa4"
            ? Math.floor(
                (new Date() - new Date(2020, 0, 1)) / 1000 / 60 / 60 / 24 / 7
              ) *
                5 +
              new Date().getDay()
            : 60;

        const chartPromises = listings.map(listing =>
          store.dispatch(
            Actions.charts.fetchBefore(
              listing.id,
              Math.floor(Date.now() / 1000), //today
              daysBack
            )
          )
        );
        const charts = await Promise.all(chartPromises);

        return { marketIndex: index };
      }
      return {};
    }

    return {};
  },
  async home(store, match, queryClient) {
    const categories = await queryClient.fetchQuery(getCategoriesQuery())
    const articles = await queryClient.fetchQuery(getHomeArticlesQuery())
    const authors = await queryClient.fetchQuery(getAuthorsQuery())
    const authorIds = [ ... new Set(articles.map( article => article.articleAuthorId ))];
    await Promise.all(
      authorIds.map(
        async id =>
          await store.dispatch(
            Actions.users.fetchUserData(parseInt(id))
          )
      )
    );

    const largestPaintImage = articles[0].articleFeaturedImageUrl;

    await store.dispatch(Actions.marketIndices.fetchAll());    
    await queryClient.prefetchQuery(reportQuery("companyFollowerDeltaReport"))
    await queryClient.prefetchQuery(seriesListQuery())
    await queryClient.prefetchQuery(recentMediaAssetsQuery("audio"))
    //await queryClient.prefetchQuery(getHomeArticlesQuery())

    const images = getSrcset(largestPaintImage, 'PREVIEW');
    
    return { preload: [{ as:"image", href:images.src }] }
  },

  async markets({ dispatch }, { params }, queryClient) { 
    const livePrices = false   
    const { leafname } = params;
    const { id } = find({ leafname }, marketIndices);

    if (id) {
      const data = await getMarketMovers(id, livePrices);      
      queryClient.setQueryData(["marketMovers", id, livePrices], data);

      const ranking = get("[0].rankings", data);
      const listingIds = [...ranking.top, ...ranking.bottom];      

      if (!isEmpty(listingIds)) {
        const fetchListings = (listingIds) =>
          dispatch(Actions.advCompanies.listings.getByIds(listingIds));
        const fetchIsues = (issueIds) =>
          dispatch(Actions.advCompanies.issues.getByIds(issueIds));
        const fetchCompanies = (companyIds) =>
          dispatch(Actions.advCompanies.companies.getByIds(companyIds));

        await fetchListings(listingIds)
          .then((data) => data.result.map((listing) => listing.issueId))
          .then(fetchIsues)
          .then((data) => data.result.map((issue) => issue.issueCompanyId))
          .then(fetchCompanies);

        await dispatch(Actions.quotes.fetchQuotes(listingIds, livePrices));
      }
    }    
  },

  async ipoRedirect(){
    return redirect(
      `/`
    )
  }
};
